import React from 'react';
import TabbedImageGallery from './ImageSlider_V3';

const App = () => {
  const imageGroups = [
    {
      tabLabel: 'Breakfast',
      images: [
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(2)_gHaGX2epe.png?updatedAt=1723871940452",
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(3)_IfVx2RAES.png?updatedAt=1723871932901",

      ],
      altText: ['First Image', 'Second Image'],
    },
    {
      tabLabel: 'Brunch',
      images: [
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/7_20241109_194847_0001_pk5WLAejO.png?updatedAt=1731235456056",
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(11)_r1CaeezuZ.png?updatedAt=1723871988903",
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/6_20241109_194846_0000_NlZ8sOjlo.png?updatedAt=1731235455228",
      ],
      altText: ['brunch 1', 'brunch 2', 'brunch 3'],
    },

    {
        tabLabel: 'Special',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(1)__7mYa7Oh4.png?updatedAt=1723871940820",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/updates/menu_20240904_091658_0000_-IZjqvt5rO.png?updatedAt=1725437963805",
        ],
        altText: ['special 1', 'special 2'],
      },


      {
        tabLabel: 'Salads',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(8)_mhvegU6pF.png?updatedAt=1723871940209",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(9)_6mI2TdBCB.png?updatedAt=1723871939638",
        ],
        altText: ['Salads 1', 'Salad 2'],
      },

      {
        tabLabel: 'Nigerian Soups & Swallow',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(4)_LJr-BGPCX.png?updatedAt=1723871944784",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(5)_08u10h4fl.png?updatedAt=1723871941724",
        ],
        altText: ['soup1', 'Soup 2'],
      },
      {
        tabLabel: 'Sandwiches',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(7)_kO3-YyhxA.png?updatedAt=1723871940193",
        ],
        altText: ['sandwish 1'],
      },
      {
        tabLabel: 'Drinks',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(13)_rFt5_Up0z.png?updatedAt=1723871973894",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(14)_i85qngzjo.png?updatedAt=1723871986145",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/Milkshake_20241111_165343_0000_maXwH5QUq6.png?updatedAt=1731340844586",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(16)_tCcxW2fO8.png?updatedAt=1723871985477",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/3_20241109_175335_0002__pYGAJ6Ze.png?updatedAt=1731235454125",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/Expresso_20241110_084126_0000_hxYcpx4Z1D.png?updatedAt=1731234999833",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/N4,500_20241109_192831_0000_lXo8GQZ7Q7.png?updatedAt=1731235453592"

        ],
        altText: ['Drinks 1', 'Drinks 2', 'Drinks 3', 'Drinks 4', 'Drinks 5', 'Drinks 6', 'Drinks 7'],
      },
      {
        tabLabel: 'Juices',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Updated%20V2/Juice_20241109_201141_0000_T-vqkC80o.png?updatedAt=1731235451217"

        ],
        altText: ['Juice 1',],
      },
      


    // Add more groups as needed
  ];

  return (
    <div className="App">
      <h1 className='flex justify-center items-center mt-5 bg-white z-10'>Our Menu List</h1>
      <TabbedImageGallery imageGroups={imageGroups} />
    </div>
  );
};

export default App;